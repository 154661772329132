import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SupplierCreateUpdateForm, SupplierListFilterForm } from '../../../supplier/forms';
import { SupplierContactCreateForm } from '../../../supplier/forms/supplier-contact-create.form';
import { SupplierInvoiceCreateUpdateForm } from '../../../supplier/forms/supplier-invoice-create-update.form';
import { SupplierInvoiceListFilterForm } from '../../../supplier/forms/supplier-invoice-list-filter.form';
import { SupplierInvoiceLotAssociateForm } from '../../../supplier/forms/supplier-invoice-lot-associate.form';
import { SupplierLotCreateForm } from '../../../supplier/forms/supplier-lot-create.form';
import { SupplierOrderCreateUpdateForm } from '../../../supplier/forms/supplier-order-create-update.form';
import { SupplierOrderListFilterForm } from '../../../supplier/forms/supplier-order-list-filter.form';
import { SupplierContact } from '../../models/supplier/supplier-contact.model';
import { SupplierInvoice } from '../../models/supplier/supplier-invoice.model';
import { SupplierLot } from '../../models/supplier/supplier-lot.model';
import { SupplierOrder } from '../../models/supplier/supplier-order.model';
import { Supplier } from '../../models/supplier/supplier.model';
import { HttpRequestService } from '../http/http-request.service';
import { SupplierInvoicePaymentCreateForm } from '../../../supplier/forms/supplier-invoice-payment-create.form';
import { SupplierCreditGenerationForm } from '../../../supplier/forms/supplier-credit-generation.form';

@Injectable()
export class SupplierService {
	constructor(private httpRequestService: HttpRequestService) {
	}

	/**
	 * Supplier
	 */
	public getSupplierList(form: SupplierListFilterForm): Observable<Supplier[] | null> {
		return this.httpRequestService.post('supplier/list', form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Supplier, result.suppliers) as Supplier[];
		}));
	}

	public createSupplier(form: SupplierCreateUpdateForm): Observable<Supplier | null> {
		return this.httpRequestService.post('supplier', form.toRequestForm()).pipe(map(result => {
			return this.httpRequestService.mapToModel(Supplier, result.supplier) as Supplier;
		}));
	}

	public getSupplierDetail(supplierId: number): Observable<Supplier | null> {
		return this.httpRequestService.get(`supplier/${supplierId}`).pipe(map((result) => {
			return this.httpRequestService.mapToModel(Supplier, result.supplier) as Supplier;
		}));
	}


	public updateSupplier(supplierId: number, form: SupplierCreateUpdateForm): Observable<Supplier | null> {
		return this.httpRequestService.put(`supplier/${supplierId}`, form.toRequestForm()).pipe(map(result => {
			return this.httpRequestService.mapToModel(Supplier, result.supplier) as Supplier;
		}));
	}

	public archiveSupplier(supplierId: number): Observable<Supplier | null> {
		return this.httpRequestService.put(`supplier/${supplierId}/archive`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(Supplier, result.supplier) as Supplier;
		}));
	}

	public unarchiveSupplier(supplierId: number): Observable<Supplier | null> {
		return this.httpRequestService.put(`supplier/${supplierId}/unarchive`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(Supplier, result.supplier) as Supplier;
		}));
	}

	public deleteSupplier(supplierId: number): Observable<any> {
		return this.httpRequestService.delete(`supplier/${supplierId}/delete`);
	}

	/**
	 * Supplier contact
	 */
	public createSupplierContact(supplierId: number, form: SupplierContactCreateForm): Observable<SupplierContact | null> {
		return this.httpRequestService.post(`supplier/contact`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierContact, result.contact) as SupplierContact;
			}));
	}

	public updateSupplierContact(supplierId: number, contactId: number, form: SupplierContactCreateForm):
		Observable<SupplierContact | null> {
		return this.httpRequestService.put(`supplier/contact/${contactId}`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierContact, result.contact) as SupplierContact;
			}));
	}

	public deleteSupplierContact(supplierId: number, contactId: number): Observable<any> {
		return this.httpRequestService.delete(`supplier/contact/${contactId}`);
	}

	/**
	 * Supplier order
	 */
	public getSupplierOrderDetail(supplierOrderId: number): Observable<SupplierOrder | null> {
		return this.httpRequestService.get(`supplier/order/${supplierOrderId}`).pipe(map((result) => {
			return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_order) as SupplierOrder;
		}));
	}

	public createSupplierOrder(form: SupplierOrderCreateUpdateForm): Observable<SupplierOrder | null> {
		return this.httpRequestService.post(`supplier/order`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_order) as SupplierOrder;
			}));
	}

	public archiveSupplierOrder(supplierOrderId: number): Observable<SupplierOrder | null> {
		return this.httpRequestService.put(`supplier/order/${supplierOrderId}/archive`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_order) as SupplierOrder;
		}));
	}

	public updateSupplierOrder(orderId: number, form: SupplierOrderCreateUpdateForm): Observable<SupplierOrder | null> {
		return this.httpRequestService.put(`supplier/order/${orderId}`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_order) as SupplierOrder;
			}));
	}

	public cancelSupplierOrder(supplierOrderId: number): Observable<SupplierOrder | null> {
		return this.httpRequestService.put(`supplier/order/${supplierOrderId}/cancel`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_order) as SupplierOrder;
		}));
	}

	public getSupplierOrderList(form: SupplierOrderListFilterForm): Observable<SupplierOrder[] | null> {
		console.log(form.toRequestForm());
		return this.httpRequestService.post('supplier/order/list', form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_orders) as SupplierOrder[];
		}));
	}

	public sendSupplierOrderValidationRequest(supplierOrderId: number): Observable<SupplierOrder | null> {
		return this.httpRequestService.post(`supplier/order/${supplierOrderId}/validation-request`, {}).pipe(map((result) => {
			return result;
		}));
	}

	public validateSupplierOrder(supplierOrderId: number): Observable<SupplierOrder | null> {
		return this.httpRequestService.put(`supplier/order/${supplierOrderId}/validate`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_order) as SupplierOrder;
		}));
	}

	public denySupplierOrder(supplierOrderId: number): Observable<SupplierOrder | null> {
		return this.httpRequestService.put(`supplier/order/${supplierOrderId}/deny`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(SupplierOrder, result.supplier_order) as SupplierOrder;
		}));
	}

	/**
	 * Supplier lot
	 */
	public createSupplierLot(supplierOrderId: number, form: SupplierLotCreateForm): Observable<SupplierLot | null> {
		return this.httpRequestService.post(`supplier/order/${supplierOrderId}/lot`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierLot, result.contact) as SupplierLot;
			}));
	}

	public updateSupplierLot(supplierOrderId: number, form: SupplierLotCreateForm): Observable<SupplierLot | null> {
		return this.httpRequestService.put(`supplier/order/${supplierOrderId}/lot`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierLot, result.contact) as SupplierLot;
			}));
	}

	/**
	 * Supplier Invoice
	 */

	public getSupplierInvoiceDetail(supplierInvoiceId: number): Observable<SupplierInvoice | null> {
		return this.httpRequestService.get(`supplier/invoice/${supplierInvoiceId}`).pipe(map((result) => {
			return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
		}));
	}

	public createSupplierInvoice(form: SupplierInvoiceCreateUpdateForm): Observable<SupplierInvoice | null> {
		return this.httpRequestService.post(`supplier/invoice`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
			}));
	}

	public updateSupplierInvoice(invoiceId: number, form: SupplierInvoiceCreateUpdateForm): Observable<SupplierInvoice | null> {
		return this.httpRequestService.put(`supplier/invoice/${invoiceId}`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
			}));
	}

	public getSupplierInvoiceList(form: SupplierInvoiceListFilterForm): Observable<SupplierInvoice[] | null> {
		return this.httpRequestService.post('supplier/invoice/list', form.toRequestForm()).pipe(map((result) => {
			return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoices) as SupplierInvoice[];
		}));
	}

	public sendSupplierInvoiceValidationRequest(supplierInvoiceId: number): Observable<SupplierInvoice | null> {
		return this.httpRequestService.put(`supplier/invoice/${supplierInvoiceId}/validation-request`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
		}));
	}

	public validateSupplierInvoice(supplierInvoiceId: number): Observable<SupplierInvoice | null> {
		return this.httpRequestService.put(`supplier/invoice/${supplierInvoiceId}/validate`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
		}));
	}

	public denySupplierInvoice(supplierInvoiceId: number): Observable<SupplierInvoice | null> {
		return this.httpRequestService.put(`supplier/invoice/${supplierInvoiceId}/deny`, {}).pipe(map(result => {
			return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
		}));
	}

	public associateInvoiceLot(form: SupplierInvoiceLotAssociateForm): Observable<SupplierInvoice | null> {
		return this.httpRequestService.post(`supplier/invoice/lot`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
			}));
	}

	public updateInvoiceLot(form: SupplierInvoiceLotAssociateForm): Observable<SupplierInvoice | null> {
		return this.httpRequestService.post(`supplier/invoice/lot`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
			}));
	}

	public deleteInvoiceLot(supplierInvoiceId: number, supplierInvoiceLotId: number): Observable<SupplierInvoice | null> {
		return this.httpRequestService.delete(`supplier/invoice/${supplierInvoiceId}/lot/${supplierInvoiceLotId}`)
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
			}));
	}

	/**
	 * Supplier Invoice Payments
	 */
	public addNewInvoicePayment(supplierInvoiceId: number, form: SupplierInvoicePaymentCreateForm): Observable<SupplierInvoice | null> {
		return this.httpRequestService.post(`supplier/invoice/${supplierInvoiceId}/payment`, form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierInvoice, result.supplier_invoice) as SupplierInvoice;
			}));
	}

	/**
	 * Supplier Credits
	 */
	public generateCreditPdf(form: SupplierCreditGenerationForm) {
		return this.httpRequestService.post('supplier/credit/generate', form.toRequestForm())
			.pipe(map(result => {
				return this.httpRequestService.mapToModel(SupplierInvoice, result.credit) as SupplierInvoice;
			}));
	}

	public previewCreditPdf(form: SupplierCreditGenerationForm) {
		return this.httpRequestService.postDownloadFile('supplier/credit/preview', form.toRequestForm(), 'pdf')
			.pipe(map(result => {
				return result;
			}));
	}
}
