// AUTH
export const __API_LOGIN__ = 'login';
export const __API_LOGOUT__ = 'logout';
export const __API_REFRESH_TOKEN__ = 'refresh-token';
export const __API_PASSWORD_REMIND__ = 'password/remind ';
export const __API_PASSWORD_RESET__ = 'password/reset ';
export const __API_PASSWORD_TOKEN_VALIDATE__ = 'password/validate-token ';
export const __API_CURRENT_USER_PERMISSIONS__ = 'user/permissions';
export const __API_CURRENT_USER_ROLE_ = 'user/role';

// USER
export const __API_USER_SWITCH_PROFILE_ = 'user/profile/switch';
export const __API_USER_PROFILE__ = 'user/{0}/information';
export const __API_USER_NOTIFICATION_GET__ = 'user/notification';
export const __API_USER_NOTIFICATION_AS_READ__ = 'user/notification/{0}/read';
export const __API_USER_NOTIFICATION_AS_UNREAD__ = 'user/notification/{0}/unread';
export const __API_USER_NOTIFICATION_DELETE__ = 'user/notification/{0}';
export const __API_USER_FIRSTNAME_UPDATE__ = 'user/firstname';
export const __API_USER_NOTIFICATION_EMAIL_UPDATE__ = 'user/notification-email';
export const __API_USER_LASTNAME_UPDATE__ = 'user/lastname';
export const __API_USER_PASSWORD_UPDATE__ = 'user/password';
export const __API_USER_CAR_CREATE__ = 'user/car';
export const __API_USER_CAR_UPDATE__ = 'user/car';

export const __API_ADMIN_USER_LIST_GET__ = 'admin/user/list';
export const __API_ADMIN_USER_DETAIL_GET__ = 'admin/user/username/{0}/detail';
export const __API_ADMIN_USER_CREATE__ = 'admin/user/create';
export const __API_ADMIN_USER_PROFILE_ADD__ = 'admin/user/{0}/profile/add';
export const __API_ADMIN_MANAGER_LIST_GET__ = 'admin/manager/list';
// this api return only the start and end date of new profile which use can add, does not create profile
export const __API_ADMIN_USER_PROFILE_ADD_AVAILABLE_PROFILE__ = 'admin/user/{0}/profile/available';

// Role
export const __API_ADMIN_ROLE_LIST_GET__ = 'admin/user/role-list';

// profile
export const __API_ADMIN_PROFILE_LIST_BY_DATE__ = 'admin/profile/list';
export const __API_ADMIN_PROFILE_LIST_CURRENT_GET__ = 'admin/profile/list/current';
export const __API_ADMIN_PROFILE_CREATE__ = 'admin/profile/create';
export const __API_ADMIN_PROFILE_DELETE__ = 'admin/profile/{0}/delete';
export const __API_ADMIN_PROFILE_POST_DESCRIPTION_LIST_GET__ = 'admin/profile/post-description/list';

export const __API_ADMIN_PROFILE_POST_DESCRIPTION_FILE_UPLOAD__ = 'profile/post-description/{0}/file';
export const __API_ADMIN_PROFILE_POST_DESCRIPTION_FILE_DELETE__ = 'profile/post-description/{0}/file/{1}';

// fiscal period
export const __API_ADMIN_FISCAL_PERIOD_CURRENT_GET__ = 'admin/fiscal-period/current';
export const __API_ADMIN_FISCAL_PERIOD_LIST_GET__ = 'admin/fiscal-period/list';
export const __API_ADMIN_FISCAL_PERIOD_SIMPLE_LIST_GET__ = 'admin/fiscal-period/list/simple';
export const __API_ADMIN_FISCAL_PERIOD_GET__ = 'admin/fiscal-period/{0}/detail';
export const __API_ADMIN_FISCAL_PERIOD_CREATE__ = 'admin/fiscal-period';
export const __API_ADMIN_FISCAL_PERIOD_UPDATE__ = 'admin/fiscal-period';
export const __API_ADMIN_FISCAL_PERIOD_LATEST_GET__ = 'admin/fiscal-period/latest';

// Declaration management
export const __API_MANAGEMENT_DECLARATION_USER_LIST_GET__ = 'admin/management-declaration/user/list';

// Absence
export const __API_ABSENCE_FILE_UPLOAD__ = 'absence/{0}/file';
export const __API_ABSENCE_FILE_DELETE__ = 'absence/{0}/file/{1}';

// Expense report
export const __API_EXPENSE_REPORT_TYPE_LIST__ = 'expense/report/type/list';
export const __API_EXPENSE_REPORT_MY_REPORT_LIST__ = 'expense/report/list';
export const __API_EXPENSE_REPORT_DELETE__ = 'expense/report/{0}/delete';
export const __API_EXPENSE_REPORT_GET__ = 'expense/report/{0}';
export const __API_EXPENSE_REPORT_UPDATE__ = 'expense/report/{0}/update';
export const __API_EXPENSE_REPORT_CREATE__ = 'expense/report/create';
export const __API_EXPENSE_REPORT_CREATE_MULTI__ = 'expense/report/create/multi';
export const __API_EXPENSE_REPORT_FILE_UPLOAD__ = 'expense/report/{0}/file';
export const __API_EXPENSE_REPORT_FILE_DELETE__ = 'expense/report/{0}/file/{1}';

// Expense report request
export const __API_EXPENSE_REPORT_REQUEST_GET__ = 'request/expense-report/{0}';
export const __API_EXPENSE_REPORT_REQUEST_FILE_UPLOAD__ = 'request/expense-report/{0}/file';
export const __API_EXPENSE_REPORT_REQUEST_FILE_DELETE__ = 'request/expense-report/{0}/file/{1}';

// Project
export const __API_PROJECT_SEARCH__ = 'project/search';
export const __API_PROJECT_INTERVENTION_CREATE__ = 'project/{0}/intervention';

// ===== ADMIN URL ========
export const __API_EXPENSE_REPORT_LIST_BY_USER__ = 'admin/expense-report/user/{0}/list';

// FILE UPLOAD CUSTOMER
export const __API_CUSTOMER_FILE_UPLOAD__ = 'customer/{0}/file';
export const __API_CUSTOMER_FILE_DELETE__ = 'customer/{0}/file/{1}';

// FILE UPLOAD PROJECT
export const __API_PROJECT_FILE_UPLOAD__ = 'project/{0}/file';
export const __API_PROJECT_AMENDMENT_CREATE__ = 'project/{0}/amendment';
export const __API_PROJECT_PTF_UPLOAD__ = 'project/{0}/ptf';
export const __API_PROJECT_ORDER_FILE_UPLOAD__ = 'project/{0}/order/{1}/file';
export const __API_PROJECT_DELIVERY_FILE_UPLOAD__ = 'project/{0}/delivery/{1}/file';
export const __API_PROJECT_FILE_DELETE__ = 'project/{0}/file/{1}';

// FILE UPLOAD USER
export const __API_MANAGE_USER_ANNUAl_ASSESSMENT_INTERVIEW_CREATE__ = 'manage/user/{0}/annual-assessment-interview';
export const __API_MANAGE_USER_ANNUAl_ASSESSMENT_INTERVIEW_DELETE__ = 'manage/user/{0}/annual-assessment-interview/{1}';
export const __API_USER_FILE_UPLOAD__ = 'user/{0}/file';
export const __API_USER_FILE_DELETE__ = 'user/{0}/file/{1}';

// PROJECT
export const __API_PROJECT_ORDER_DETAIL__ = 'project/{0}/order/{1}';
export const __API_PROJECT_ORDER_REMAINING_FEE_AND_WORKLOAD = 'project/{0}/remaining-fee-workload';

// PROJECT AMENDMENT
export const __API_PROJECT_AMENDMENT_DELETE__ = 'project/{0}/amendment/{1}';

// REPORTING
export const __API_REPORTING_CUSTOMERS__ = 'reporting/customer';
export const __API_REPORTING_LOTS_TO_INVOICE__ = 'reporting/lots-to-invoice';
export const __API_REPORTING_DECLARATION_SELF__ = 'reporting/declaration-self';
export const __API_REPORTING_DECLARATION_ALL__ = 'reporting/declaration-all';
export const __API_REPORTING_MONTHLY_DECLARATIONS__ = 'reporting/monthly-declarations';
export const __API_REPORTING_MONTHLY_DECLARATIONS_FOR_ALL__ = 'reporting/monthly-declarations-all';

// REPORTING STATISTICS
export const __API_REPORTING_STATISTICS_PROJECTS__ = 'reporting/statistics/project';
export const __API_REPORTING_STATISTICS_DELIVERY__ = 'reporting/statistics/delivery';
export const __API_REPORTING_STATISTICS_CUSTOMER__ = 'reporting/statistics/customer';

export const __API_REPORTING_SILAE_ABSENCE__ = 'reporting/silae/absence';
export const __API_REPORTING_SILAE_ABSENCE_FOR_ALL__ = 'reporting/silae/absence/all';

// FILE DOWNLOAD
export const __API_REPORTING_MONTHLY_EXPENSE_FILES_DOWNLOAD__ = 'reporting/monthly-declarations/files';
export const __API_REPORTING_MONTHLY_EXPENSE_FILES_DOWNLOAD_ALL__ = 'reporting/monthly-declarations/files/all';

// BILLING REPORTING
export const __API_REPORTING_BILLING_DELIVERIES__ = 'reporting/billing/deliveries';
export const __API_REPORTING_BILLING_INVOICES__ = 'reporting/billing/invoices';

// ADMIN DECLARATIONS MANAGE
export const __API_ADMIN_DECLARATION_MONTHLY_REPORT_GET_ = 'declaration/user/{0}/monthly-report';
export const __API_ADMIN_DECLARATION_ANNUAL_COUNT_GET_ = 'declaration/user/{0}/annual-count';
export const __API_ADMIN_DECLARATION_MONTHLY_VALIDATE__ = 'declaration/user/{0}/monthly/validate';
export const __API_ADMIN_DECLARATION_DAILY_VALIDATE__ = 'declaration/user/{0}/daily/validate';

// Get management user cars list require "Admin.User.Manage"
export const __API_ADMIN_DECLARATION_GET_USER_CARS_LIST__ = 'manage/user/{0}/cars/list';

// Manager user permission
export const __API_USER_PERMISSIONS__ = 'manage/user/{0}/permission';
export const __API_MANAGE_USER_UPDATE_PERMISSION__ = 'manage/user/{0}/permission';

// FILE UPLOAD CONTACT DATABASE
export const __API_CONTACT_DATABASE_FILE_UPLOAD__ = 'contact/import';

// USER GUIDE
export const __API_USER_GUIDE_PAGE__ = 'user-guide/page/{0}';

// ========== SUPPLIER ==========

// SUPPLIER
export const __API_SUPPLIER_FILE_UPLOAD__ = 'supplier/{0}/file';
export const __API_SUPPLIER_FILE_DELETE__ = 'supplier/{0}/file/{1}';

// SUPPLIER INVOICE
export const __API_SUPPLIER_INVOICE_FILE_UPLOAD__ = 'supplier/invoice/{0}/file';
export const __API_SUPPLIER_INVOICE_FILE_DELETE__ = 'supplier/invoice/{0}/file/{1}';

// ========== PROJECT MANAGER ==========
export const __API_PMANAGER_MANAGE_USER_LIST_GET__ = 'admin/pmanager/management/user/list';
