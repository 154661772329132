export enum PermissionTypeEnum {

    WEB_AUTH_SIGN_IN = 'Web.Auth.SignIn',

    ADMIN_USER_MANAGE = 'Admin.User.Manage',
    ADMIN_REPORTING_MANAGE = 'Admin.Reporting.Manage',

    ADMIN_PROFILE_MANAGE = 'Admin.Profile.Manage',

    ADMIN_PROJECT_MANAGE = 'Admin.Project.Manage',
    ADMIN_PROJECT_READ = 'Admin.Project.Read',

    ADMIN_DECLARATION_MANAGE = 'Admin.Declaration.Manage',
    ADMIN_DECLARATION_ALLACCESS = 'Admin.Declaration.AllAccess',
    ADMIN_REQUEST_MANAGE = 'Admin.Request.Manage',

    ADMIN_CUSTOMER_READ = 'Admin.Customer.Read',
    ADMIN_CUSTOMER_MANAGE = 'Admin.Customer.Manage',

    ADMIN_SUPPLIER_MANAGE = 'Admin.Supplier.Manage',

    SELF_PROFILE_MANAGE = 'Self.Profile.Manage',
    SELF_DECLARATION_MANAGE = 'Self.Declaration.Manage',
    SELF_REQUEST_MANAGE = 'Self.Request.Manage',
    SELF_PROJECT_MANAGE = 'Self.Project.Manage',
    SELF_PROJECT_READ = 'Self.Project.Read',

    ADMIN_STATISTICS_PROJECT = 'Admin.Statistics.Project',
    ADMIN_STATISTICS_CUSTOMER = 'Admin.Statistics.Customer',
    ADMIN_STATISTICS_BUSINESSSECTOR = 'Admin.Statistics.BusinessSector',
    ADMIN_STATISTICS_COST = 'Admin.Statistics.Cost',
    ADMIN_STATISTICS_TURNOVERBYPROJECTTYPE = 'Admin.Statistics.TurnoverByProjectType',
    ADMIN_STATISTICS_DELIVERYINVOICE = 'Admin.Statistics.DeliveryInvoice',
    ADMIN_STATISTICS_USER = 'Admin.Statistics.User',
    ADMIN_STATISTICS_CUSTOMERSATISFACTION = 'Admin.Statistics.CustomerSatisfaction',
    ADMIN_STATISTICS_PROJECTUSERSATISFACTION = 'Admin.Statistics.ProjectUserSatisfaction',
    ADMIN_STATISTICS_PROJECTMANAGERCOMMENT = 'Admin.Statistics.ProjectManagerComment',
    ADMIN_STATISTICS_PROJECTCOMMENT = 'Admin.Statistics.ProjectComment',
}
